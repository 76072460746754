$(document).ready(function(){
  // --------------Маска на телефон--------------------------------
    Inputmask({
       mask: "+38 (999) 999-99-99",
       clearMaskOnLostFocus: true,
       clearIncomplete: true,
       showMaskOnHover: false,
   }).mask('.phone');
// --------------Маска на телефон--------------------------------
// ------------------callback--------------------------------------
  $("#do_callback").click(function (e) {
    $("#callback").submit();
  });

  $("#callback").validate({
      errorElement: 'span',
      errorClass: 'input__error',
      ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input__block'));
    },
    highlight: function(element) {
        $(element).parents('.input__wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input__wrapper').removeClass("forms__error");
    },
      submitHandler: function(form) {
          var csrf_token = $('meta[name="csrf-token"]').attr('content');
          var formdata = $("#callback").serialize();
          $("#callback")[0].reset();
          $.ajax({
              url: routes.postSend,
              type: 'POST',
              data: {
                  "_token" : csrf_token,
                   "data": formdata,
                   "subj": "callback",
              },
              success: function(data) {
                  $('#success_callback').removeClass('js_hide');
                setTimeout(function() {
                  var modalActive = document.querySelector('.modal.js_active');
                  var html = document.querySelector('html');
                  modalActive.classList.remove('js_active');
                  html.style.overflow = "";
                   $('#success_callback').addClass('js_hide');
               }, 4000)
              }
          })
      }
    });
  // -----------------callback--------------------------------------------------------------
// ------------------feedback--------------------------------------
  $("#do_feedback").click(function (e) {
    $("#feedback").submit();
  });

  $("#feedback").validate({
      errorElement: 'span',
      errorClass: 'input__error',
      ignoreTitle: true,
    errorPlacement: function(error, element) {
        error.appendTo($(element).parents('.input__block'));
    },
    highlight: function(element) {
        $(element).parents('.input__wrapper').addClass("forms__error");
    },
    unhighlight: function(element) {
        $(element).parents('.input__wrapper').removeClass("forms__error");
    },
      submitHandler: function(form) {
          var csrf_token = $('meta[name="csrf-token"]').attr('content');
          var formdata = $("#feedback").serialize();
          $("#feedback")[0].reset();
          $.ajax({
              url: routes.postSend,
              type: 'POST',
              data: {
                  "_token" : csrf_token,
                   "data": formdata,
                   "subj": "feedback",
              },
              success: function(data) {
                  $('.js_select').selectric({
                     disableOnMobile: false,
                     nativeOnMobile: false
                 });
                  $('#success_feedback').removeClass('js_hide');
                setTimeout(function() {
                   $('#success_feedback').addClass('js_hide');
               }, 4000)
              }
          })
      }
    });
  // -----------------feedback--------------------------------------------------------------
  // ------------------service_reg--------------------------------------
    $("#do_service_reg").click(function (e) {
      $("#service_reg").submit();
    });

    $("#service_reg").validate({
        errorElement: 'span',
        errorClass: 'input__error',
        ignoreTitle: true,
      errorPlacement: function(error, element) {
          error.appendTo($(element).parents('.input__block'));
      },
      highlight: function(element) {
          $(element).parents('.input__wrapper').addClass("forms__error");
      },
      unhighlight: function(element) {
          $(element).parents('.input__wrapper').removeClass("forms__error");
      },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#service_reg").serialize();
            $("#service_reg")[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                     "data": formdata,
                     "subj": "service_reg",
                },
                success: function(data) {
                    $('.js_select').selectric({
                       disableOnMobile: false,
                       nativeOnMobile: false
                   });
                    $('#success_service_reg').removeClass('js_hide');
                  setTimeout(function() {
                     $('#success_service_reg').addClass('js_hide');
                 }, 4000)
                }
            })
        }
      });
    // -----------------service_reg--------------------------------------------------------------
    // ------------------test_drive--------------------------------------------------------------
    $("#do_test_drive").click(function (e) {
      $("#test_drive").submit();
    });

    $("#test_drive").validate({
        errorElement: 'span',
        errorClass: 'input__error',
        ignoreTitle: true,
      errorPlacement: function(error, element) {
          error.appendTo($(element).parents('.input__block'));
      },
      highlight: function(element) {
          $(element).parents('.input__wrapper').addClass("forms__error");
      },
      unhighlight: function(element) {
          $(element).parents('.input__wrapper').removeClass("forms__error");
      },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#test_drive").serialize();
            $("#test_drive")[0].reset();
            $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                     "data": formdata,
                     "subj": "test_drive",
                },
                success: function(data) {
                    $('.js_select').selectric({
                       disableOnMobile: false,
                       nativeOnMobile: false
                   });
                    $('#success_test_drive').removeClass('js_hide');
                  setTimeout(function() {
                     $('#success_test_drive').addClass('js_hide');
                 }, 4000)
                }
            })
        }
      });
    // ------------------test_drive--------------------------------------------------------------
});
