 var nav_additions = document.querySelector('.js_additions_nav_scroll'),
     nav_section = document.querySelector('.js_additions_nav_section'),
     viewportHeight = $(window).height(),
     additions_block = document.querySelectorAll('.js_additions_block');

function additionsNavScroll () {
  if (($(nav_section).offset().top + nav_additions.offsetHeight) < window.pageYOffset && window.pageYOffset + nav_additions.offsetHeight) {
          nav_additions.classList.add('js_fixed');
  }
  else  {
    nav_additions.classList.remove('js_fixed');
  }
}

function activeAdditonsNav () {
    $('.js_additions_block').each(function() {
        var thisHeight = $(this).offset().top;
        if (thisHeight <= window.pageYOffset) {
            var href = $(this).attr("data-id");
            $('.js_anchor_addit').removeClass('js_active');
            $('.js_anchor_addit[data-href= "' + href + '"]').addClass('js_active');
        }
    });
}

function additionsLightcase () {
  var $allLinks = document.getElementsByTagName('a'),
     $popupLinks = [];
     for (var i = 0; i < $allLinks.length; i++) {
         if ($allLinks[i].getAttribute('data-rel') != null) {
             $popupLinks.push($allLinks[i]);
         }
     };
   $($popupLinks).lightcase({
       transition: 'scrollHorizontal',
       showSequenceInfo: false,
       showTitle: false,
       showCaption: false,
       shrinkFactor: 0.9,
       swipe: true,
       navigateEndless: false,
       maxWidth: '90%',
       maxHeight: '90%'
   });
}

function additionsGalleryScroll() {
  $('.js_six_gallery').on('wheel', (function(e) {
    if (e.originalEvent.deltaY < 0) {
      $(this).slick('slickPrev');
    } else {
      $(this).slick('slickNext');
    }
  }));
}

var sliderIsLive = false;

function slickColor () {
  $('.js_color_slick_nav').slick({
      dots: false,
      rows: 0,
      speed: 1000,
      autoplay: false,
      arrows: false,
      draggable: true,
      infinite:false,
      autoplay: false,
      autoplaySpeed: 0,
      speed: 500,
      cssEase: 'linear',
      swipe: true,
      // focusOnSelect: true,
      variableWidth: true,
  });
  sliderIsLive = true;
};

function countNumber () {
  $('.js_count').each(function () {
      $(this).parents('.js_additions_chars').removeClass('js_opacity');
      $(this).prop('Counter', 0).animate({
          Counter:$(this).text()
      },{
          duration: 1500,
          easing: 'linear',
          // easing: "swing",
          step:function(now){
              // $(this).text(Math.ceil(now));
              $(this).text(Number(now.toFixed(1)));
          }
      })
  });
}

window.onscroll = function(){
   if (nav_additions) {
       additionsNavScroll();
   }
   if(additions_block) {
     activeAdditonsNav();
   }
   var aboutCounter = document.querySelector('.js_tth_text');
   if(aboutCounter) {
       var topPos = aboutCounter.offsetTop;
       var heightBlock = aboutCounter.offsetHeight;
       var r = topPos - window.pageYOffset;
       if(r < heightBlock / 2 && r > -heightBlock / 2 && $(".js_tth_text").hasClass('js_active')) {
           countNumber();
           $(".js_tth_text").removeClass('js_active');
       }
   }

}
window.onload = function () {
   if (nav_additions) {
       additionsNavScroll();
   }
   if(additions_block) {
     activeAdditonsNav();
   }
}

$(document).ready(function(){
// ----------------------- tth_text START-----------------------------------------------------------
  var aboutCounter = document.querySelector('.js_tth_text');
  if(aboutCounter) {
      var topPos = aboutCounter.offsetTop;
      var heightBlock = aboutCounter.offsetHeight;
      var r = topPos - window.pageYOffset;
      if(r < heightBlock / 2 && r > -heightBlock / 2 && $(".js_tth_text").hasClass('js_active')) {
          countNumber();
          $(".js_tth_text").removeClass('js_active');
      }
  }
// ----------------------- tth_text FINISH-----------------------------------------------------------
// -------------------video_on_photo START------------------------------------------
  	$(document).on('click', '.js_video_on_foto_open', function(e) {
          var modal = $(this).attr('data-modal');
          $('.js_additions_modal[data-modal="'+modal+'"]').addClass('js_active');
          $('body').addClass('overflow');
          var video = $('.js_additions_modal[data-modal="'+modal+'"]').find('.js_video')
          if(video.length) {
            var video_src = video.attr('data-link');
            video.attr('src', video_src);
            video.get(0).play();
          } else {
            console.log(false);
          }
    });

    $(document).on('click', '.js_additions_modal_overlay, .js_additions_modal_close', function(e) {
          $('.js_additions_modal').removeClass('js_active');
          var video = $(this).parents('.js_additions_modal').find('.js_video');
          if(video.length) {
            video.get(0).pause();
          } else {
            console.log(false);
          }

          $('body').removeClass('overflow');
    });

    $(document).on('click', '.js_anchor_addit', function (e) {
       $('.js_anchor_addit').removeClass('js_active');
       $(this).addClass('js_active');
       var id  = $(this).attr('data-href'),
           top = $('.js_additions_block[data-id="'+id+'"]').offset().top + $('.js_additions_nav_scroll').height();
       $('body,html').animate({scrollTop: top}, 1500);
       additionsNavScroll ();
       return false;
   });

// ---------------------video_on_photo FINISH---------------------------------------
// ---------------six_gallery START-------------------------------------------------
  $('.js_six_gallery').on('init', function(event, slick){
      var key = 1;
      slick.$slides.children().each(function(){
          $(this).addClass('six_gallery_slide six_gallery_slide_' + key);
          key ++;
      })
  });

  $('.js_six_gallery').slick({
    vertical:true,
    verticalSwiping: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    speed: 1000,
    cssEase: 'linear',
    dots: true,
    arrows: false,
    rows: 3,
    slidesPerRow: 1,
    infinite: false,
    draggable: true,
    swipe: false,
    lazyLoad: 'ondemand',
    responsive:[
            {
                breakpoint : 769,
                settings: {
                    rows: 1,
                    vertical: false,
                    verticalSwiping: false,
                    swipe: true,
                    speed: 300,
                    infinite: true,
                }
            },
         ]
  });

  additionsLightcase ();

  if($(window).width() > 768) {
    additionsGalleryScroll();
  }

  $('.js_six_gallery').on('breakpoint', function(event, slick, breakpoint) {
    if(breakpoint == 769) {
        additionsLightcase();
    }
    if(breakpoint == null) {
        additionsLightcase();
        var key = 1;
        slick.$slides.children().each(function(){
            $(this).addClass('six_gallery_slide six_gallery_slide_' + key);
            key ++;
        });
        additionsGalleryScroll();
    }
  });
// ---------------six_gallery FINISH-------------------------------------------------
// --------------js_horizontal_gallery START----------------------------------------
$('.js_horizontal_gallery').slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    rows: 0,
    speed: 1000,
    autoplay: false,
    arrows: false,
    draggable: true,
    infinite:false,
    autoplay: false,
    autoplaySpeed: 0,
    speed: 500,
    cssEase: 'linear',
    swipe: true,
    focusOnSelect: true,
    lazyLoad: 'ondemand',
    // centerPadding: '10vw',
});

$('.js_horizontal_gallery').on('beforeChange', function(event, slick, currentSlide, nextSlide){
   if(nextSlide == 0) {
       slick.$slider.removeClass("js_center");
       slick.$slider.removeClass("js_left");
       slick.$slider.addClass("js_right");
  }
  else if (nextSlide == slick.slideCount - 1){
     slick.$slider.removeClass("js_center");
     slick.$slider.removeClass("js_right");
     slick.$slider.addClass("js_left");
   }
   else {
     slick.$slider.removeClass("js_right");
     slick.$slider.removeClass("js_left");
     slick.$slider.addClass("js_center");
   }
});
// -----------------js_horizontal_gallery FINISH -----------------------------------
// ------------------js_colors START -----------------------------------------------
  $(document).on('click', '.js_color_btn', function(e) {
        var parent = $(this).parents('.js_colors');
        parent.find('.js_color_btn').removeClass('js_active');
        parent.find('.js_color_img').removeClass('js_active');
        $(this).addClass('js_active');
        var btn_color = $(this).attr('data-name');
        parent.find('.js_color_img[data-name="'+btn_color+'"]').addClass('js_active');
        var img_color = parent.find('.js_color_img[data-name="'+btn_color+'"]');
        var src_color = img_color.find('.js_color_img_picture');
        if(img_color.find('.js_color_img_source').attr('srcset') == '') {
          img_color.find('.js_color_img_source').attr('srcset', src_color.attr('data-srcset'));
        }
        if (img_color.find('.js_color_img_img').attr('src') == '') {
          img_color.find('.js_color_img_img').attr('src',  src_color.attr('data-src'));
        }
  });

  $('.js_colors').each(function(){
      var first_color_btn = $(this).find('.js_color_btn').first();
      first_color_btn.trigger('click');
  });

  if ($(window).width() >= 1200) {
    if(sliderIsLive == true) {
      $('.js_color_slick_nav').slick('unslick');
      sliderIsLive = false;
    }
  } else {
    if (sliderIsLive == false) {
      slickColor ();
      sliderIsLive = true;
    }
  }
// ------------------js_colors FINISH -----------------------------------------------
});

$(window).on('resize', function () {
  if ($(window).width() >= 1200) {
    if(sliderIsLive == true) {
      $('.js_color_slick_nav').slick('unslick');
      sliderIsLive = false;
    }
  }
  else {
    if (sliderIsLive == false) {
      slickColor ();
      sliderIsLive = true;
    }
  }
});
