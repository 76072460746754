var Main = (function() {
    $(function(){
        Main.init();
    })
    return{
        init: function(){
            var _self = this;
        				_self.mainPage();
        				_self.headerMenu();
        				_self.headerSubMenu();
                _self.headerAdaptive();
                _self.selectStyle();
                _self.upButton();
                _self.breadcrumbs();
                _self.iframeAdmin();
				        _self.unitSlider();
                _self.sliderCar();
                _self.galleryPopup();                
        },
				mainPage: function () {
					var timeSlider = $("#fullpage").attr('data-timeout')*1000;
						$('#fullpage').fullpage({
								scrollHorizontally: false,
								parallax: true,
								parallaxKey: 'YWx2YXJvdHJpZ28uY29tXzlNZGNHRnlZV3hzWVhnPTFyRQ==',
								parallaxOptions: {
										type: 'reveal',
										percentage: 62,
										property: 'translate'
								},
								navigation: true,
								navigationPosition: 'right',
								css3: true,
								scrollingSpeed: 1000,
								autoScrolling: true,
								scrollBar: false,
								easing: 'easeInOutCubic',
								easingcss3: 'ease',
								fadingEffect: true,
								responsiveWidth: 0,
								responsiveHeight: 0,
								lazyLoading: true,
                // afterLoad: function(origin, destination, direction){
                // 	var leavingSection = this;
                // },
                // onLeave: function(origin, destination, direction){
                // 	var leavingSection = this;
                //   var timer;
                // },
								// afterRender: function () {
								//     setInterval(function () {
								//         $.fn.fullpage.moveSectionDown();
								//     }, timeSlider);
								// },
						});
				},
				headerMenu: function () {
					$(document).on('click', '.js_menu_link', function(e) {
						e.stopPropagation();
						e.preventDefault();
						 if(!$(this).hasClass('js_active')) {
							 $('.js_menu_link').removeClass('js_active');
							 $(this).addClass('js_active');
							 $('.js_submenu').removeClass('js_active');
							 $(this).next('.js_submenu').addClass('js_active');
							 $('body').addClass('overflow');
							 $('.js_header').removeClass('js_active_left');
							 $('.js_header').removeClass('js_active_right');
							 if($(this).attr('data-toggle') == "left") {
								 $('.js_header').addClass('js_active_left');
							 }
							 else if ($(this).attr('data-toggle') == "right") {
								 $('.js_header').addClass('js_active_right');
							 }
               $('.menu__link').addClass('js_hidden');
						} else {
							 $(this).removeClass('js_active');
							 $('.js_header').removeClass('js_active_left');
							 $('.js_header').removeClass('js_active_right');
							 $('.js_submenu').removeClass('js_active');
							 $('body').removeClass('overflow');
               $('.menu__link').removeClass('js_hidden');
						}
					 });
           $(document).on('click', '.js_submenu_back', function(e) {
             e.stopPropagation();
             e.preventDefault();
              $('.js_submenu').removeClass('js_active');
              $('.menu__link').removeClass('js_hidden');
           });
				},
				headerSubMenu: function () {
					if($('.js_model_link.js_active').length == 0) {
		         $('.js_model_link').first().addClass('js_active');
		 				 $('.js_model_link').first().next('.js_submenu_progress').addClass('js_active');
		      }
		 		   $(".js_model_link").hover(
		 			  function() {
		 				 $('.js_model_link').removeClass('js_active');
		 				 $('.js_submenu_progress').removeClass('js_active');
		 			   $(this).addClass('js_active');
		 				 $(this).next('.js_submenu_progress').addClass('js_active');
		 			  }
		 			);
				},
        headerAdaptive: function () {
          $(document).on('click', '.js_adaptive_btn', function(e) {
            e.stopPropagation();
            e.preventDefault();
            if(!$(this).hasClass('js_active')) {
              $(this).addClass('js_active');
              $('.js_menu_cover').addClass('js_active');
              $('body').addClass('overflow');
            } else {
              $(this).removeClass('js_active');
              $('.js_menu_cover').removeClass('js_active');
              $('body').removeClass('overflow');
              $('.js_menu_link').removeClass('js_active');
              $('.js_submenu').removeClass('js_active');
              $('.menu__link').removeClass('js_hidden');
              $('.js_header').removeClass('js_active_left');
              $('.js_header').removeClass('js_active_right');
            }
          });
        },
        selectStyle: function () {
            $('.js_select').selectric({
                  disableOnMobile: false,
                  nativeOnMobile: false,
            });
        },
        upButton: function () {
          // var upButton = document.getElementById('up_button');
          //  function scrollToTop(event) {
          //      event.preventDefault();
          //      $('html,body').animate({
          //          scrollTop: 0,
          //      },500)
          //  };
          //  upButton.addEventListener('click',scrollToTop,true);
          //  window.onscroll = function(){
          //      if ($(this).scrollTop() > 350){
          //          upButton.classList.add('js_visible');
          //      }
          //      else{
          //          upButton.classList.remove('js_visible');
          //      }
          //  };
       },
       breadcrumbs: function () {
           $(".js-lastcrumb").click(function(e) {
                e.stopPropagation();
                e.preventDefault();
           });
       },
       iframeAdmin: function () {
           $(".text__admin").find('iframe').each(function() {
               $(this).closest('p').addClass('iframe_holder').wrap("<div class='iframe_container'></div>");
           });
       },
			 unitSlider: function () {
							 $('.js_unit_slider').slick({
								 dots: true,
								 arrows: false,
								 autoplay: true,
                 autoplaySpeed: 5000,
								 slidesToShow: 3,
								 slidesToScroll: 1,
								 infinite: true,
								 draggable: true,
								 swipe: true,
								 lazyLoad: 'ondemand',
								 responsive:[
												 {
														 breakpoint : 768,
														 settings: {
																slidesToShow: 2,
														 }
												 },
											]
						 });
			 },
       sliderCar: function () {
         $('.js_stock_car_slider').slick({
             dots: false,
             arrows: false,
             rows: 0,
             slidesToShow: 1,
             slidesToScroll: 1,
             infinite: false,
             swipe: true,
             fade: true,
             draggable: true,
             lazyLoad: 'ondemand',
             asNavFor: '.js_stock_car_nav',
          });
          $('.js_stock_car_nav').slick({
             asNavFor: '.js_stock_car_slider',
             dots: false,
             arrows: true,
             rows: 0,
             slidesToShow: 4,
             slidesToScroll: 1,
             infinite: false,
             swipe: true,
             focusOnSelect: true,
             draggable: true,
             lazyload: 'ondemand',
             prevArrow: '<div class="slick_arrow_prev slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.8 490.8"><path d="M362.7 490.8c-2.8 0-5.5-1.1-7.6-3.1L120.5 253c-4.2-4.2-4.2-10.9 0-15.1L355.1 3.3c4.1-4.2 10.8-4.4 15.1-0.3 4.2 4.1 4.4 10.8 0.3 15.1 -0.1 0.1-0.2 0.2-0.3 0.3L143.1 245.5l227.1 227.1c4.2 4.2 4.2 10.9 0 15.1C368.2 489.7 365.5 490.8 362.7 490.8z"/><path d="M362.7 490.8c-2.8 0-5.5-1.1-7.6-3.1L120.5 253c-4.2-4.2-4.2-10.9 0-15.1L355.1 3.3c4.1-4.2 10.8-4.4 15.1-0.3 4.2 4.1 4.4 10.8 0.3 15.1 -0.1 0.1-0.2 0.2-0.3 0.3L143.1 245.5l227.1 227.1c4.2 4.2 4.2 10.9 0 15.1C368.2 489.7 365.5 490.8 362.7 490.8z"/></svg></div>',
             nextArrow: '<div class="slick_arrow_next slick-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.8 490.8"><path d="M135.7 3.1c-4.2-4.1-11-4-15.1 0.3 -4 4.1-4 10.7 0 14.8l227.1 227.1L120.6 472.5c-4.2 4.1-4.4 10.8-0.3 15.1 4.1 4.2 10.8 4.4 15.1 0.3 0.1-0.1 0.2-0.2 0.3-0.3l234.7-234.7c4.2-4.2 4.2-10.9 0-15.1L135.7 3.1z"/><path d="M128.1 490.7c-5.9 0-10.7-4.8-10.7-10.6 0-2.8 1.1-5.6 3.1-7.6l227.1-227.1L120.6 18.2c-4.2-4.2-4.2-10.9 0-15.1 4.2-4.2 10.9-4.2 15.1 0l234.7 234.7c4.2 4.2 4.2 10.9 0 15.1L135.7 487.5C133.7 489.6 131 490.7 128.1 490.7z"/></svg></div>',
             responsive:[
                     {
                         breakpoint : 576,
                         settings: {
                            slidesToShow: 3,
                         }
                     },
                     {
                         breakpoint : 480,
                         settings: {
                            slidesToShow: 2,
                         }
                     },
                  ]
          });
       },
       galleryPopup: function () {
           var $allLinks = document.getElementsByTagName('a'),
              $popupLinks = [];
              for (var i = 0; i < $allLinks.length; i++) {
                  if ($allLinks[i].getAttribute('data-rel') != null) {
                      $popupLinks.push($allLinks[i]);
                  }
              };
          $($popupLinks).lightcase({
              transition: 'scrollHorizontal',
              showSequenceInfo: false,
              showTitle: false,
              showCaption: false,
              shrinkFactor: 0.9,
              swipe: true,
              navigateEndless: false,
              maxWidth: '90%',
              maxHeight: '90%'
          });
      },
    }
})(jQuery);
