import $ from 'jquery';
window.$ = window.jQuery = $;

//  ленивая загрузка картинок
import lazySizes from 'lazysizes';
// Карусель /слайдер
import 'slick-carousel';
// Модалка
// require("magnific-popup");
require("lightcase");
// Плагин для мобильных устройств touch галереи
var loadTouchEvents = require('jquery-touch-events');
loadTouchEvents($);
// Валидация форм
require('jquery-validation');
let locale = $('html').attr('lang');
if (locale !== "en") {
  require('jquery-validation/dist/localization/messages_'+ locale +'.min.js');
}

// Select style
require('selectric');
// Маска на телефон
require('inputmask');

require('./modernizr-custom');

import fullpage from 'fullpage.js/dist/jquery.fullpage.js';
require('fullpage.js/vendors/scrolloverflow.min.js');



require('./main');
require('./additions');
require('./form');
require('./modals');
