var btnsModal = document.querySelectorAll('.js_modal_btn');
if (btnsModal) {
    for (var i = 0; i< btnsModal.length; i++) {
        document.addEventListener('click', modalShow);
        document.addEventListener('keydown', modalShow);
    }
};


function modalShow (event) {
	var event = event.target;
    var html = document.querySelector('html');
    if(event.closest('.js_modal_btn')){
        var button = event.closest('.js_modal_btn');
        if(button.dataset.modal) {
            // Определяем кнопку открытия и передаем ее дату (data-modal) в id модалки
            var nameModal = button.dataset.modal,
                idModal = String(nameModal),
                modal = document.getElementById(idModal);
            // открываем модалку
            if(!modal.classList.contains('js_active')) {
                modal.classList.add('js_active');
                html.style.overflow = "hidden";
            }
        }
    }
    // Закрытие по overlay
	else if (event.classList.contains('js_modal_overlay')) {
		event.parentNode.classList.remove('js_active');
        html.style.overflow = "";
	}
    // Закрытие модалки по кнопке close с data (data-close) и передаем в модалку с id
	else if (event.closest('.js_modal_close')) {
        var close = event.closest('.js_modal_close'),
	 	    nameClose = close.dataset.close,
	        idClose = String(nameClose),
	        modalClose = document.getElementById(idClose);
		if (modalClose.classList.contains('js_active')) {
			modalClose.classList.remove('js_active');
            html.style.overflow = "";
		}
	}
    // Закрытие по ESC
	else if (event == "Escape" || event == "Esc" || event == 27) {
    	onkeydown();
    }
};

document.onkeydown = function(evt) {
    evt = evt || window.event;
    var modalActive = document.querySelector('.modal.js_active');
    var html = document.querySelector('html');
    var isEscape = false;
    if ("key" in evt) {
        isEscape = (evt.key == "Escape" || evt.key == "Esc");
    } else {
        isEscape = (evt.keyCode == 27);
    }
    if (isEscape) {
        modalActive.classList.remove('js_active');
        html.style.overflow = "";
    }
};
